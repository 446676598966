import React, {useContext} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import FavoriteProductChecked from '../../../public/static/svg/favoriteProductChecked.svg';
import FavoriteProductUnchecked from '../../../public/static/svg/favoriteProductUnchecked.svg';

type Size = 'small' | 'normal' | 'large';

const CONTAINER_SIZE_MAP = {
	small: 'w-6 h-6',
	normal: 'w-8 h-8',
	large: 'w-10 h-10',
};

type PropsType = {
	checked: boolean;
	loading?: boolean;
	size?: Size;
	handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Favorite = ({
	checked,
	size = 'normal',
	loading = false,
	handleClick,
}: PropsType) => {
	const {translate} = useContext(I18nContext);
	return (
		<button
			onClick={e => handleClick && handleClick(e)}
			aria-label={translate('favorite')}
			type="button"
			disabled={!!loading}
			className={cx(
				'bg-white hover:bg-red-200 py-2 px-2 rounded-full focus:outline-none outline-none z-10',
				CONTAINER_SIZE_MAP[size],
			)}
		>
			{checked ? (
				<FavoriteProductChecked />
			) : (
				<FavoriteProductUnchecked />
			)}
		</button>
	);
};

export default Favorite;
