import isNil from 'lodash/isNil';

const CurrencyFormatter = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});
export const formatPrice = (price?: number | null | undefined): string =>
	!isNil(price) ? CurrencyFormatter.format(price) : '';
export const formatPriceInEuro = (price?: number | null | undefined): string =>
	!isNil(price) ? CurrencyFormatter.format(price / 100) : '';
export const calcPercent = (total: number, number: number): number =>
	100 - (number * 100) / total;
