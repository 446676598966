import React from 'react';
import cx from 'classnames';

type Props = {
	text: string;
	classes?: string;
	icon: React.ReactNode;
};

const ProductBadgeState = ({text, classes, icon}: Props) => (
	<p
		className={cx(
			'flex self-end items-center text-white rounded-tr leading-none px-3 py-2 z-20',
			classes,
		)}
	>
		{icon}
		<span className="pl-1 font-bold text-xxs">{text.toUpperCase()}</span>
	</p>
);

export default ProductBadgeState;
