import cx from 'classnames';
import React, {useContext} from 'react';
import {I18nContext} from '../../../i18n';
import LockIcon from '../../../public/static/svg/locked.svg';
import ArrowIcon from '../../../public/static/svg/next-arrow.svg';
import TagsIcon from '../../../public/static/theme-capcar/tags-duotone.svg';
import {InternalStatus} from '../../../types/generated-gateway-api-types';
import {getAvailabilitySchema} from '../../../utils/metadata';
import {formatPrice} from '../../../utils/priceHelper';
import ProductBadge from '../ProductBadge';

type Props = {
	price?: number;
	isPriceDecreased: boolean;
	state?: string;
	financing?: number;
	url: string;
	isGoodDeal: boolean;
	isVeryGoodDeal: boolean;
	isFirstHand: boolean;
	warranty: string | null | undefined;
};

const INFO_STATE = {
	RESERVED: {
		text: 'reservedProduct',
		textColor: 'bg-red-450',
	},
	SOLD: {
		text: 'soldProduct',
		textColor: 'bg-darkBlue-980',
	},
};

const warrantyToDisplay = ['Garantie constructeur'];

const ProductCardBadges = ({
	price,
	isPriceDecreased,
	state,
	financing,
	url,
	isGoodDeal,
	isVeryGoodDeal,
	isFirstHand,
	warranty,
}: Props) => {
	const {translate} = useContext(I18nContext);

	const isForSale = state && state === InternalStatus.FOR_SALE;
	const hasWarranty = !!warranty && warrantyToDisplay.includes(warranty);

	const showWarranty = isForSale && hasWarranty;
	const showVeryGoodDeal = isForSale && !showWarranty && isVeryGoodDeal;
	const showGoodDeal =
		isForSale && !showWarranty && !isVeryGoodDeal && isGoodDeal;

	const showFirstHand =
		isForSale &&
		!showWarranty &&
		!isVeryGoodDeal &&
		!showGoodDeal &&
		isFirstHand;

	return (
		<div className="flex absolute z-10 w-full top-0 inset-x-0 bottom-0">
			{isPriceDecreased && (
				<p className="absolute bg-green-550 px-2 left-0 text-xxs text-white top-0 flex">
					<TagsIcon className="fill-current mr-1 self-center" />{' '}
					<span className="my-0_6 uppercase">
						{translate('priceCut')}
					</span>
				</p>
			)}

			{!isForSale && INFO_STATE[state] && (
				<ProductBadge
					text={translate(INFO_STATE[state].text)}
					classes={INFO_STATE[state].textColor}
					icon={<LockIcon className="fill-current h-2_5 w-3" />}
				/>
			)}

			{showWarranty && (
				<ProductBadge
					text={warranty}
					classes="bg-blue-350"
					icon={<TagsIcon className="fill-white h-2_5 w-3" />}
				/>
			)}

			{showVeryGoodDeal && (
				<ProductBadge
					text={translate('isVeryGoodDeal')}
					classes="bg-yellow-600"
					icon={<TagsIcon className="fill-white h-2_5 w-3" />}
				/>
			)}

			{showGoodDeal && (
				<ProductBadge
					text={translate('isGoodDeal')}
					classes="bg-yellow-600"
					icon={<TagsIcon className="fill-white h-2_5 w-3" />}
				/>
			)}

			{showFirstHand && (
				<ProductBadge
					text={translate('isFirstHand')}
					classes="bg-green-550"
					icon={<TagsIcon className="fill-white h-2_5 w-3" />}
				/>
			)}

			{!!price && (
				<p className="bg-white rounded-t shadow-mg px-3 py-2 z-20 text-gray-800 relative ml-auto self-end transform translate-y-1/2">
					{isPriceDecreased && (
						<span className="bg-green-550 absolute -left-2 -top-2 leading-none rounded-full transform mr-1">
							<ArrowIcon
								width=".9rem"
								height=".9rem"
								className="block fill-current rotate-45 text-white transform"
								style={{
									padding: '1px',
									margin: '1px',
								}}
							/>
						</span>
					)}
					<span
						className={cx(
							'text-2xl tablet:text-xl font-bold text-center block leading-none',
							{
								'opacity-50 text-darkGray-800':
									state === 'SOLD',
								'text-blue-275': state !== 'SOLD',
							},
						)}
						itemProp="offers"
						itemScope
						itemType="http://schema.org/Offer"
					>
						{state && (
							<meta
								itemProp="availability"
								content={getAvailabilitySchema(state)}
							/>
						)}
						<meta itemProp="price" content={String(price)} />
						<meta itemProp="priceCurrency" content="EUR" />
						<meta itemProp="url" content={url} />
						{formatPrice(price)}
					</span>
					{financing && (
						<span
							className={cx(
								'text-sm tablet:text-xs leading-none block',
								state !== 'SOLD'
									? 'text-blue-275'
									: 'text-gray-100',
							)}
						>
							{translate('financingAmount', {
								price: formatPrice(Math.round(financing)),
							})}
						</span>
					)}
				</p>
			)}
		</div>
	);
};

export default ProductCardBadges;
