import {InternalStatus} from '../types/generated-gateway-api-types';

const STATUS_AVAILABILITY_MAP = {
	[InternalStatus.FOR_SALE]: 'http://schema.org/InStock',
	[InternalStatus.RESERVED]: 'http://schema.org/OutOfStock',
	[InternalStatus.PRE_RESERVED]: 'http://schema.org/OutOfStock',
	[InternalStatus.SOLD_BY_KYUMP]: 'http://schema.org/SoldOut',
	SOLD: 'http://schema.org/SoldOut',
};
export const getAvailabilitySchema = (internalStatus: string) =>
	STATUS_AVAILABILITY_MAP[internalStatus] ||
	STATUS_AVAILABILITY_MAP[InternalStatus.FOR_SALE];
