import {productToSlug} from '@capcar/utils-product';
import cx from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import range from 'lodash/range';
import React, {useEffect, useState} from 'react';
import {useFavoriteProducts} from '../../../hooks';
import {ENERGIES_SHORTENED, GEAR_BOXES} from '../../../i18n/product-fr';
import type {TrackingType} from '../../../types/TrackingType';
import {ProductStatus} from '../../../utils/algolia-repository';
import {computeCloudinaryPictureUri} from '../../../utils/image-utils';
import Favorite from '../../system/Favorite';
import {ImageCloudinaryResponsive} from '../../system/Image';
import Link, {LinkType} from '../../system/Link';
import ProductCardBadges from './Badges';

type Props = {
	product: {
		id: string;
		brand: string;
		version: string;
		year: number;
		mileage: number;
		price?: number;
		carPackage: string;
		motorization: string;
		gearbox: string;
		energyDisplay: string;
		state?: string;
		imageId: string;
		financing?: number;
		critAir: number;
		lastPriceDecreaseDate: string | null | undefined;
		isGoodDeal: boolean;
		isVeryGoodDeal: boolean;
		isFirstHand: boolean;
		warranty: string | null | undefined;
	};
	tracking?: TrackingType;
	enablePriceDropBadge?: boolean;
	nofollow?: boolean;
};

const ProductCard = ({
	product: {
		id,
		state,
		brand,
		version,
		carPackage,
		motorization,
		year,
		mileage,
		energyDisplay,
		gearbox,
		price,
		financing,
		imageId,
		lastPriceDecreaseDate,
		isGoodDeal,
		isVeryGoodDeal,
		isFirstHand,
		warranty,
	},
	tracking,
	enablePriceDropBadge = false,
	nofollow = false,
}: Props) => {
	const [firstRender, setFirstRender] = useState(true);
	const isPriceDecreased =
		lastPriceDecreaseDate && state !== ProductStatus.SOLD
			? differenceInDays(new Date(), parseISO(lastPriceDecreaseDate)) < 7
			: false;
	const url = `/voiture-occasion/${productToSlug({
		brand,
		version,
		id,
	})}`;
	const {isFavorite, toggleFavoriteProduct, loading} =
		useFavoriteProducts(id);

	useEffect(() => {
		setFirstRender(false);
	}, []);

	return (
		<Link
			href="/voiture-occasion/[slug]"
			as={url}
			type={LinkType.IN_APP}
			tracking={tracking}
		>
			<a
				rel={nofollow ? 'nofollow' : undefined}
				itemScope
				itemType="https://schema.org/Vehicle"
				className={cx(
					'flex flex-col bg-white transitionAllCubic cursor-pointer hover:shadow-card rounded overflow-hidden shadow-cardXs',
					{
						'brightness-75 hover:brightness-100': [
							ProductStatus.SOLD,
							ProductStatus.RESERVED,
						].includes(state as ProductStatus),
					},
				)}
			>
				<meta itemProp="name" content={`${brand} ${version}`} />
				<meta itemProp="url" content={url} />
				<meta
					itemProp="image"
					content={computeCloudinaryPictureUri(imageId)}
				/>

				<div className="relative">
					<ImageCloudinaryResponsive
						name={imageId}
						alt={`${brand} ${version}`}
						srcSetSizes={range(300, 800, 50)}
						sizeDefault="100vw"
						sizeByBreakpoint={{
							md: '50vw',
							tablet: '30vw',
							desktop: '362px',
						}}
						width="352"
						height="224"
						className="rounded-t transitionAllEaseOut object-cover bg-lightBlue-400 bg-no-repeat bg-center w-full h-56 tablet:h-48"
					/>

					<ProductCardBadges
						price={price}
						isGoodDeal={isGoodDeal}
						isVeryGoodDeal={isVeryGoodDeal}
						isFirstHand={isFirstHand}
						isPriceDecreased={
							!firstRender &&
							enablePriceDropBadge &&
							isPriceDecreased
						}
						url={url}
						state={state}
						financing={financing}
						warranty={warranty}
					/>

					{!firstRender && state !== ProductStatus.SOLD && (
						<div className="absolute top-0 right-0 px-4 py-2 z-20">
							<Favorite
								checked={isFavorite}
								loading={loading}
								handleClick={e => toggleFavoriteProduct(e)}
							/>
						</div>
					)}
				</div>
				<div className="flex flex-wrap pb-4 pt-2 px-4 relative">
					<p
						className="text-lg tablet:text-base text-darkBlue-980 font-bold overflow-x-hidden truncate w-full leading-tight"
						style={{
							width: `calc(100% - ${price ? '120px' : '0px'})`,
						}}
					>
						<span itemProp="brand">{brand}</span>{' '}
						<span itemProp="model">{version}</span>
					</p>
					<div className="flex text-sm text-darkBlue-980 py-3 w-full">
						<div className="max-w-full overflow-hidden self-center truncate leading-tight">
							{carPackage} - {motorization}
						</div>
					</div>
					<div className="text-xs text-darkBlue-980 truncate flex justify-between items-center w-full leading-tight font-light">
						<div className="text-left">{year}</div>
						<div className="border-r border-gray-10 w-px h-full mx-2" />
						<div
							className="text-center"
							itemProp="mileageFromOdometer"
						>
							{mileage} km
						</div>
						<div className="border-r border-gray-10 w-px h-full mx-2" />
						<div
							className="flex-shrink text-center overflow-hidden whitespace-nowrap overflow-ellipsis"
							title={ENERGIES_SHORTENED[energyDisplay]}
							itemProp="fuelType"
						>
							{ENERGIES_SHORTENED[energyDisplay]}
						</div>
						<div className="border-r border-gray-10 w-px h-full mx-2" />
						<div
							className="text-right"
							itemProp="vehicleTransmission"
						>
							{GEAR_BOXES[gearbox]}
						</div>
					</div>
				</div>
			</a>
		</Link>
	);
};

export default ProductCard;
