import algoliasearch from 'algoliasearch';
import config from '../config';
import type {Hit} from '../types/Algolia';
import type {CosmicRelationshipType} from '../types/CosmicType';
import type {Product} from '../types/generated-gateway-api-types';
import {InternalStatus} from '../types/generated-gateway-api-types';

const {ALGOLIA_APPLICATION_ID, ALGOLIA_ADMIN_API_KEY, ALGOLIA_INDEX_NAME} =
	config;

export const searchClient = algoliasearch(
	ALGOLIA_APPLICATION_ID,
	ALGOLIA_ADMIN_API_KEY,
);
type CountParamsType = {
	query?: string | null | undefined;
	maxMileage?: number | null | undefined;
	maxPrice?: number | null | undefined;
	brand?: string | null | undefined;
	version?: string | null | undefined;
};

export enum ProductStatus {
	FOR_SALE = 'FOR_SALE',
	RESERVED = 'RESERVED',
	SOLD = 'SOLD',
}

const arrayToFilter = (
	elements: CosmicRelationshipType[],
	filter: string,
): string =>
	elements.map(element => `${filter}:"${element.title}"`).join(' OR ');

export const countProducts = async (
	params: CountParamsType | null | undefined,
): Promise<number> => {
	const {query, maxMileage, maxPrice, brand, version} = params ?? {};
	const filters = [];

	if (maxMileage) {
		filters.push(`mileage <= ${maxMileage}`);
	}

	if (maxPrice) {
		filters.push(`price <= ${maxPrice}`);
	}

	if (brand) {
		filters.push(`brand:"${brand}"`);
	}

	if (version) {
		filters.push(`version:"${version}"`);
	}

	return searchClient
		.search([
			{
				query: query ?? '',
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 1,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: filters.join(' AND '),
			},
		])
		.then(response => response.results[0].nbHits);
};

export const getLastProducts = async (
	number: number | null | undefined = 24,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `state:"${InternalStatus.FOR_SALE}"`,
			},
		])
		.then(response => response.results[0].hits);

export const getLastProductsWithLocation = async (
	number: number | null | undefined = 24,
	words: string[] = [],
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: `${String(ALGOLIA_INDEX_NAME)}_location`,
				params: {
					hitsPerPage: number,
					analytics: false,
					optionalWords: words,
				},
				query: words.join(' '),
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `state:"${InternalStatus.FOR_SALE}"`,
			},
		])
		.then(response => response.results[0].hits);

export const getLastSoldProducts = async (
	number: number | null | undefined = 24,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `state:"SOLD"`,
			},
		])
		.then(response => response.results[0].hits);

export const getLastSoldProductsWithLocation = async (
	number: number | null | undefined = 24,
	words: string[] = [],
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: `${String(ALGOLIA_INDEX_NAME)}_location`,
				params: {
					hitsPerPage: number,
					analytics: false,
					optionalWords: words,
				},
				query: words.join(' '),
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `state:"SOLD"`,
			},
		])
		.then(response => response.results[0].hits);

export const getProductsByInspectorId = async (
	inspectorId: number,
	status: ProductStatus[],
): Promise<Hit[]> => {
	const states = status.map(state => `state:"${state}"`).join(' OR ');

	return searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 100,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `(${states}) AND inspectorId=${inspectorId}`,
			},
		])
		.then(response => response.results[0].hits);
};

export const getProductsByAgentIds = async (
	ids: number[],
	status: ProductStatus[],
): Promise<Hit[]> => {
	const states = status.map(state => `state:"${state}"`).join(' OR ');
	const agents = ids.map(id => `inspectorId:${id}`).join(' OR ');

	return searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 100,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `(${states}) AND (${agents})`,
			},
		])
		.then(response => response.results[0].hits);
};

export const getLastProductsWithBonus = async (): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 24,
					filters: 'hasBonus=1',
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getByProducts = async (products: Product[]) =>
	getByProductIds(products.map(product => product.id));

export const getByProductIds = async (ids: string[]): Promise<Hit[]> =>
	searchClient
		.multipleGetObjects<Hit>(
			ids.map(id => ({
				objectID: id,
				indexName: String(ALGOLIA_INDEX_NAME),
			})),
		)
		.then(r => r.results.filter(doc => !!doc));

export const getAllProducts = async (attributes: string[]): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 100000,
					attributesToRetrieve: attributes,
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getAllForSaleProducts = async (
	attributes: string[],
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 100000,
					attributesToRetrieve: attributes,
					analytics: false,
				},
				// @ts-expect-error AlgoliaClient.search does not have a `filters` parameter
				filters: `state:"${InternalStatus.FOR_SALE}"`,
			},
		])
		.then(response => response.results[0].hits);

export const getLastProductsByFieldValue = async (
	field: string,
	value: string,
	number: number,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					filters: `${field}:"${value}" AND state:"${InternalStatus.FOR_SALE}"`,
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getLastProductsByBrands = async (
	brands: CosmicRelationshipType[],
	number: number,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					filters: `(${arrayToFilter(brands, 'brand')}) AND state:"${
						InternalStatus.FOR_SALE
					}"`,
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getLastProductsByCategory = async (
	category: string,
	number: number,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					filters: `category:"${category}" AND state:"${InternalStatus.FOR_SALE}"`,
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getLastProductsByVersions = async (
	versions: CosmicRelationshipType[],
	number: number,
): Promise<Hit[]> =>
	searchClient
		.search<Hit>([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: number,
					filters: `(${arrayToFilter(
						versions,
						'version',
					)}) AND state:"${InternalStatus.FOR_SALE}"`,
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].hits);

export const getMinPriceFieldValue = async (
	field: string,
	version: string,
): Promise<number> =>
	searchClient
		.search([
			{
				indexName: String(ALGOLIA_INDEX_NAME),
				params: {
					hitsPerPage: 0,
					filters: `${field}:"${version}"`,
					// @ts-expect-error AlgoliaClient.search does not have a `facets` parameter
					facets: 'price',
					analytics: false,
				},
			},
		])
		.then(response => response.results[0].facets_stats?.price?.min);
