export const ENERGIES = {
	PETROL: 'Essence',
	DIESEL: 'Diesel',
	ELECTRIC: 'Électrique',
	HYBRID_PETROL: 'Hybride essence',
	HYBRID_DIESEL: 'Hybride diesel',
	HYBRID_GAS: 'Hybride gaz',
	HYBRID_PLUG_IN: 'Hybride rechargeable',
	DUAL_FUEL_PETROL_BIOETHANOL: 'Bicarburation essence bioéthanol',
	DUAL_FUEL_PETROL_GNV: 'Bicarburation essence GNV',
	DUAL_FUEL_PETROL_GPL: 'Bicarburation essence GPL',
	BIOFUEL: 'Biocarburant',
	HYDROGEN: 'Hydrogène',
	OTHER: 'Autre',
};
export const ENERGIES_SHORTENED = {
	...ENERGIES,
	DUAL_FUEL_PETROL_BIOETHANOL: 'Essence / Bioéthanol',
	DUAL_FUEL_PETROL_GNV: 'Essence / GNV',
	DUAL_FUEL_PETROL_GPL: 'Essence / GPL',
};
export const ENERGIES_SEARCH = {
	DIESEL: 'Diesel',
	PETROL: 'Essence',
	ELECTRIC: 'Electrique',
	HYBRID: 'Hybride',
	OTHER: 'Autre',
};
export const GEAR_BOXES = {
	MANUAL: 'Manuelle',
	AUTOMATIC: 'Automatique',
	ROBOTIC: 'Robotisée',
};
export const OWNER_MANUAL = {
	EXCELLENT: 'À jour',
};
export const OWNER_MANUAL_BILLS = {
	EXCELLENT: 'Complètes',
};
export const SPARE_WHEEL = {
	RUN_FLAT: 'Roulage à plat',
	SPARE_WHEEL: 'Roue de secours',
	FULL_SPARE_WHEEL: 'Roue de secours pleine',
	ANTI_PUNCTURE: 'Kit anti-crevaison',
	WAFER: 'Galette',
};
export const DEFECT_SCHEMA = {
	EXTERIOR_LEFT: 'côté conducteur',
	EXTERIOR_RIGHT: 'côté passager',
	EXTERIOR_FRONT: "à l'avant",
	EXTERIOR_BACK: "à l'arrière",
	INTERIORS: "à l'intérieur",
};

export const BRANDS_OPTIONS = {
	bfgoodrich: 'BF Goodrich',
	bridgestone: 'Bridgestone',
	continental: 'Continental',
	dunlop: 'Dunlop',
	falken: 'Falken',
	firestone: 'Firestone',
	goodyear: 'Goodyear',
	hankook: 'Hankook',
	kleber: 'Kleber',
	kumho: 'Kumho',
	michelin: 'Michelin',
	nankang: 'Nankang',
	nexen: 'Nexen',
	nokian: 'Nokian',
	pirelli: 'Pirelli',
	uniroyal: 'Uniroyal',
	yokohama: 'Yokohama',
};

export const TIRE_TYPE = {
	SUMMER: 'Été',
	WINTER: 'Hiver',
	ALL_SEASON: '4 saisons',
};
